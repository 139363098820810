import React, { useState } from 'react';
import { Container, Image} from 'react-bootstrap';
import ProgramaMenu from '../ProgramaMenu';  
import useIntersectionObserver from '../../hooks/useIntersectionObserver';


const Programa2 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useIntersectionObserver(setIsVisible);
    return (
        <div>
            <div className='headermodule'>
                <Image src="/images/banner_vml.jpg" fluid />
            </div>
            <ProgramaMenu/>
            <Container ref={ref} className={`paddcontainer opacitycontainer ${isVisible ? 'fade-in' : ''}`}>
                <h1>Programa 3: Gestión de Clientes - Un rol estratégico</h1>
                <div className='fechaprograma'><h3>Fecha:</h3><p>Jueves 8 de agosto</p></div>
                <p>El objetivo principal de este módulo radica en proporcionar a los participantes un entendimiento profundo de la importancia estratégica que reviste la construcción y el mantenimiento de relaciones sólidas y duraderas con los clientes.</p>
                <p>Compartiremos una visión completa de la dinámica cliente-agencia, analizando las diferentes etapas del proceso: desde la creación del brief, el trabajo interno con los diferentes departamentos, el valor agregado que desde el rol se puede entregar al cliente y cómo la agencia puede convertirse en un importante socio estratégico.</p>
                <h3>Modalidad:</h3>
                <p>Teórica</p>
                <h3>Imparten:</h3>
                <div className='persona'>
                    <p><Image src="/images/personas/valentina_more.png" fluid /><a href="https://www.linkedin.com/in/valentina-mor%C3%A9-14a55b26/" target="_blank" rel="noopener noreferrer">Valentina Moré, CEO VML</a>.</p>
                    <p><Image src="/images/personas/silvina_more.png" fluid /><a href="https://www.linkedin.com/in/silvina-mor%C3%A9-aguirre-43930135/" target="_blank" rel="noopener noreferrer">Silvina Moré, COO VML</a>.</p>
                </div>            
            </Container>
        </div>
    );
};

export default Programa2;
