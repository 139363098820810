import React, { useState } from 'react';
import { Container, Image} from 'react-bootstrap';
import ProgramaMenu from '../ProgramaMenu';  
import useIntersectionObserver from '../../hooks/useIntersectionObserver';


const Programa5 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useIntersectionObserver(setIsVisible);
    return (
        <div>
            <div className='headermodule'>
                <Image src="/images/banner_vml.jpg" fluid />
            </div>
            <ProgramaMenu/>
            <Container ref={ref} className={`paddcontainer opacitycontainer ${isVisible ? 'fade-in' : ''}`}>
                <h1>Programa 5: El poder de la creatividad</h1>
                <div className='fechaprograma'><h3>Fecha:</h3><p>Jueves 15 de agosto</p></div>
                <p>En el mundo de la comunicación y el marketing, la creatividad es un pilar fundamental para el éxito. En este módulo los estudiantes se sumergirán en un análisis profundo de la creatividad y su poder transformador, examinando su naturaleza multifacética desde diferentes perspectivas. Exploraremos las etapas del proceso creativo, desde la generación de las ideas hasta la materialización de las mismas en conceptos y campañas tangibles. También exploraremos diversas técnicas de presentación para venderlas de manera exitosa.</p>
                <h3>Modalidad:</h3>
                <p>Teórica y práctica. </p>
                <h3>Imparten:</h3>
                <div className='persona'>
                    <p><Image src="/images/personas/rafael_barthaburu.png" fluid /><a href="https://www.linkedin.com/in/rafael-barthaburu-trujillo-0ba50b5" target="_blank" rel="noopener noreferrer">Rafael Barthaburu, VP Creativo VML</a>.</p>
                    <p><Image src="/images/personas/gonzalo_bullosa.png" fluid /><a href="https://www.linkedin.com/in/gonzalo-boullosa-016a4a29/" target="_blank" rel="noopener noreferrer">Gonzalo Boullosa, Head of Art VML</a>.</p>
                </div>
            </Container>
        </div>
    );
};

export default Programa5;
