import React, { useState } from 'react';
import { Container, Image} from 'react-bootstrap';
import ProgramaMenu from '../ProgramaMenu';  
import useIntersectionObserver from '../../hooks/useIntersectionObserver';


const Programa9 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useIntersectionObserver(setIsVisible);
    return (
        <div>
            <div className='headermodule'>
                <Image src="/images/banner_vml.jpg" fluid />
            </div>
            <ProgramaMenu/>
            <Container ref={ref} className={`paddcontainer opacitycontainer ${isVisible ? 'fade-in' : ''}`}>
                <h1>Programa 8: El arte de la vocería efectiva</h1>
                <div className='fechaprograma'><h3>Fecha:</h3><p>Martes 27 de agosto</p></div>
                <p>El objetivo de este módulo será compartir conocimiento sobre la importancia de la vocería, habilidades y técnicas clave para una comunicación asertiva de quienes representan una institución, compañía u organización. Se hará especial hincapié en la importancia de la comunicación en momentos de crisis reputacional. Además, realizaremos un análisis de diversos casos que ayudarán a comprender las características de un buen vocero.</p>

                <h3>Modalidad:</h3>
                <p>Teórica y práctica</p>
                <h3>Imparten:</h3>
                <div className='persona'>
                    <p><Image src="/images/personas/patricia_varela.png" fluid /><a href="https://www.linkedin.com/in/patricia-varela-98b3ba15a/" target="_blank" rel="noopener noreferrer">Patricia Varela, Directora de cuentas, Burson</a>.</p>
                    <p><Image src="/images/personas/hugo_hernandez.png" fluid /><a href="https://www.linkedin.com/in/hugo-daniel-hern%C3%A1ndez-mart%C3%ADnez-1a9a6739/" target="_blank" rel="noopener noreferrer">Hugo Hernández, Market Leader, Burson</a>.</p>
                </div>
            </Container>
        </div>
    );
};

export default Programa9;
