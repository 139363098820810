import React, { useState } from 'react';
import { Container, Image, ListGroup, ListGroupItem } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

const Cronograma = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useIntersectionObserver(setIsVisible);

    const courses = [
        { title: 'Explorando la publicidad: pasado, presente y futuro', date: '01-08-2024', link: '/programa1' },
        { title: 'Planificación estratégica: enfoque y eficacia', date: '06-08-2024', link: '/programa2' },
        { title: 'Gestión de clientes: un rol estratégico', date: '08-08-2024', link: '/programa3' },
        { title: 'Social media: cómo conectar con las audiencias', date: '13-08-2024', link: '/programa4' },
        { title: 'El Poder de la creatividad', date: '15-08-2024', link: '/programa5' },
        { title: 'Hacer que las lindas ideas, sean ideas lindas', date: '20-08-2024', link: '/programa6' },
        { title: 'Customer Experience: creando conexiones de valor', date: '22-08-2024', link: '/programa7' },
        { title: 'El arte de la vocería efectiva', date: '27-08-2024', link: '/programa8' },
        { title: 'Publicidad 3.0: cómo la tecnología está cambiando el juego', date: '29-08-2024', link: '/programa9' },
        { title: 'Explorando la inteligencia artificial: fundamentos y casos de uso', date: '03-09-2024', link: '/programa10' }
    ];

    return (
        <div>
            <div className='headermodule'>
                <Image src="/images/banner_vml.jpg" fluid />
            </div>

            <Container ref={ref} className={`paddcontainer opacitycontainer ${isVisible ? 'fade-in' : ''}`}>
                <h1>Cronograma</h1>
                <ListGroup>
                    {courses.map((course, index) => (
                        <Link to={course.link} key={index} className="text-decoration-none">
                            <ListGroupItem key={index} className="listcrono zoom">
                                <div><strong>{course.title}</strong></div>
                                <div>Fecha: <em>{course.date}</em></div>
                            </ListGroupItem>
                        </Link>
                    ))}
                </ListGroup>
            </Container>
        </div>
    );
};

export default Cronograma;
