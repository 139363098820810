import React, { useState } from 'react';
import { Container, Image} from 'react-bootstrap';
import ProgramaMenu from '../ProgramaMenu';  
import useIntersectionObserver from '../../hooks/useIntersectionObserver';


const Programa7 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useIntersectionObserver(setIsVisible);
    return (
        <div>
            <div className='headermodule'>
                <Image src="/images/banner_vml.jpg" fluid />
            </div>
            <ProgramaMenu/>
            <Container ref={ref} className={`paddcontainer opacitycontainer ${isVisible ? 'fade-in' : ''}`}>
                <h1>Programa 6: Dirección de arte - Hacer que las lindas ideas, sean ideas lindas</h1>
                <div className='fechaprograma'><h3>Fecha:</h3><p>Martes 20 de agosto</p></div>
                <p>Este módulo busca introducir a los participantes en el fascinante mundo de la conceptualización y dirección visual, explorando su rol crucial en la construcción de mensajes efectivos y campañas memorables. Hablaremos de la interpretación y reinterpretación de las ideas, centrándonos en tres de los principales pilares del trabajo gráfico de una agencia:</p>
                <ul>
                    <li>Potenciar</li>
                    <li>Embellecer</li>
                    <li>Optimizar</li>
                </ul>
                <p>Los estudiantes aprenderán a transformar conceptos o ideas en materiales gráficos bajo la guía de los referentes de arte de la agencia.</p>

                <h3>Modalidad:</h3>
                <p>Teórica y práctica</p>
                <h3>Imparten:</h3>
                <div className='persona'>
                    <p><Image src="/images/personas/gonzalo_bullosa.png" fluid /><a href="https://www.linkedin.com/in/gonzalo-boullosa-016a4a29/" target="_blank" rel="noopener noreferrer">Gonzalo Boullosa, Head of Art de VML</a>.</p>
                    <p><Image src="/images/personas/michel_rodriguez.png" fluid /><a href="https://www.linkedin.com/in/michel-rodriguez-89bb732b/" target="_blank" rel="noopener noreferrer">Michel Rodríguez, Director de Arte de VML</a>.</p>
                    <p><Image src="/images/personas/facundo_fajardo.png" fluid /><a href="https://www.linkedin.com/in/facundo-fajardo-788913236/" target="_blank" rel="noopener noreferrer">Facundo Fajardo, Director de Arte de VML</a>.</p>
                </div>
            </Container>
        </div>
    );
};

export default Programa7;
