import React, { useState, useRef } from 'react';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import useTypingEffect from '../hooks/useTypingEffect';

const ProgramInfo = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useIntersectionObserver(setIsVisible);
    const typingText = useTypingEffect("El aprendizaje continuo es la clave para el desarrollo profesional.", 20); 
    const textRef = useRef(null);

    return (
        <div>
            <div id="acercade" ref={ref} className={`opacitycontainer container mt-3 ${isVisible ? 'fade-in' : ''}`}>
                <h1 className="typing-text" ref={textRef}>{typingText}</h1>
                <p>Bienvenidos a <strong>VML Academy</strong> nuestro programa de formación para estudiantes universitarios que presenta el estado del arte del marketing, la comunicación y el diseño con una visión global.</p>
                <p>Como parte de nuestra cultura nos encanta compartir conocimiento, por eso lanzamos este programa que permite aprender con los mejores profesionales de la industria y compartir experiencias con otros estudiantes apasionados por la publicidad y el marketing.</p>
                <p>El programa, dirigido a estudiantes de 4to año de las carreras de marketing, comunicación y diseño, se desarrolla en 10 clases presenciales que serán dictados por expertos del staff senior de la agencia usando herramientas, know-how, data y casos de éxito de <strong>VML</strong>.</p>
                <p>Las inscripciones son gratuitas y están abiertas para un máximo de 20 cupos y quienes cumplan con un 80% de asistencia recibirán un certificado. Al finalizar el programa, se seleccionarán dos alumnos para una pasantía remunerada de 3 meses en la agencia durante los meses de setiembre, octubre y noviembre de 2024.</p>
                <button className="boton btn btn-primary" onClick={() => document.getElementById('aplicar').scrollIntoView({ behavior: 'smooth' })}>APLICAR</button>
            </div>
            
            <div className='fechalugar container mt-3'>
                <div className='fechalugarleft col-md-6 zoom'>
                    <h3>Fechas:</h3>
                    <p>Del 1 de agosto al 3 de setiembre</p>
                    <p>Martes y jueves de 16:00 a 18:00 h</p>
                </div>
                
                <div className='fechalugarright col-md-6 zoom'>
                    <h3>Lugar:</h3>
                    <p><strong>VML Uruguay</strong></p>
                    <p>Punta Carretas Tower</p>
                    <p>Pasaje Paseo de las Carretas 2580 - Piso 6</p>
                </div>    
            </div>
        </div>    
    );
};

export default ProgramInfo;
