import React, { useState } from 'react';
import { Container, Image} from 'react-bootstrap';
import ProgramaMenu from '../ProgramaMenu';  
import useIntersectionObserver from '../../hooks/useIntersectionObserver';


const Programa3 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useIntersectionObserver(setIsVisible);
    return (
        <div>
            <div className='headermodule'>
                <Image src="/images/banner_vml.jpg" fluid />
            </div>
            <ProgramaMenu/>
            <Container ref={ref} className={`paddcontainer opacitycontainer ${isVisible ? 'fade-in' : ''}`}>
                <h1>Programa 4: Social Media - Cómo conectar con las audiencias</h1>
                <div className='fechaprograma'><h3>Fecha:</h3><p>Martes 13 de agosto</p></div>
                <p>El objetivo de este módulo es proporcionar a los estudiantes un completo análisis del dinámico panorama de las plataformas de redes sociales y su rol crucial en la construcción de estrategias efectivas para conectar con las audiencias, así como técnicas para medir el rendimiento de las mismas y tomar decisiones fundamentadas en datos.</p>
                <p>Analizaremos las últimas tendencias del ecosistema digital y las aplicaciones más relevantes para desenvolverse con éxito y generar un impacto positivo en los objetivos de las marcas.</p>
                <h3>Modalidad:</h3>
                <p>Teórica</p>
                <h3>Imparten:</h3>
                <div className='persona'>
                    <p><Image src="/images/personas/rodrigo_melian.png" fluid /><a href="https://www.linkedin.com/in/rodrigomelian/" target="_blank" rel="noopener noreferrer">Rodrigo Melián, VP Digital VML</a>.</p>
                    <p><Image src="/images/personas/paula_moreno.png" fluid /><a href="https://www.linkedin.com/in/paula-moreno-de-mello/" target="_blank" rel="noopener noreferrer">Paula Moreno, Social Media Leader VML</a>.</p>
                </div>    
            </Container>
        </div>
    );
};

export default Programa3;
