import React from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const courses = [
    {
        title: 'Explorando la publicidad: pasado, presente y futuro',
        description: 'Este primer módulo ofrece una mirada profunda al fascinante mundo de la publicidad, explorando su evolución desde sus inicios hasta las tendencias que están definiendo su futuro. A través de un análisis histórico, los estudiantes comprenderán cómo la publicidad ha moldeado...',
        image: '/images/programa1.png',
        link: '/programa1'
    },
    {
        title: 'Planificación estratégica: enfoque y eficacia',
        description: 'En el dinámico panorama actual de la comunicación, la capacidad de elaborar e implementar estrategias efectivas es crucial para el éxito. Este módulo proporcionará las herramientas y el conocimiento necesarios para destacarse en este desafiante entorno. A lo largo de...',
        image: '/images/programa2.png',
        link: '/programa2'
    },
    {
        title: 'Gestión de clientes: un rol estratégico',
        description: 'El objetivo principal de este módulo radica en proporcionar a los participantes un entendimiento profundo de la importancia estratégica que reviste la construcción y el mantenimiento de relaciones sólidas y duraderas con los clientes. Compartiremos una visión completa de...',
        image: '/images/programa1.png',
        link: '/programa3'
    },
    {
        title: 'Social media: cómo conectar con las audiencias',
        description: 'El objetivo de este módulo es proporcionar a los estudiantes un completo análisis del dinámico panorama de las plataformas de redes sociales y su rol crucial en la construcción de estrategias efectivas para conectar con las audiencias, así como técnicas para medir el...',
        image: '/images/programa2.png',
        link: '/programa4'
    },
    {
        title: 'El poder de la creatividad',
        description: 'En el mundo de la comunicación y el marketing, la creatividad se erige como un pilar fundamental para el éxito. En este módulo los estudiantes se sumergirán en un análisis profundo de la creatividad y su poder transformador, examinando su naturaleza multifacética desde...',
        image: '/images/programa1.png',
        link: '/programa5'
    },
    
    {
        title: 'Hacer que las lindas ideas, sean ideas lindas',
        description: 'Este módulo busca introducir a los participantes en el fascinante mundo de la conceptualización y dirección visual, explorando su rol crucial en la construcción de mensajes efectivos y campañas memorables. Hablaremos de la interpretación y reinterpretación de las ideas...',
        image: '/images/programa2.png',
        link: '/programa6'
    },
    {
        title: 'Customer experience: creando conexiones de valor',
        description: 'En el competitivo panorama actual, comprender, optimizar y mejorar la experiencia del cliente (CX) se ha convertido en un factor crítico para el éxito de cualquier marca. En este módulo abordaremos la evolución del concepto, su importancia estratégica y su impacto en los...',
        image: '/images/programa1.png',
        link: '/programa7'
    },
    {
        title: 'El arte de la vocería efectiva',
        description: 'El objetivo de este módulo será compartir conocimiento sobre la importancia de la vocería, habilidades y técnicas clave para una comunicación asertiva de quienes representan una institución, compañía u organización. Se hará especial hincapié en la importancia de la comunicación...',
        image: '/images/programa2.png',
        link: '/programa8'
    },
    {
        title: 'Publicidad 3.0. cómo la tecnología está cambiando el juego',
        description: 'Hoy en día, la tecnología es una parte fundamental de nuestra vida y no podemos ignorar su impacto en el mundo de la comunicación. En este módulo hablaremos sobre cómo aplicamos nuevas tecnologías tanto en los procesos de trabajo como en la producción de los proyectos...',
        image: '/images/programa1.png',
        link: '/programa9'
    },
    {
        title: 'Explorando la inteligencia artificial: fundamentos y casos de uso',
        description: 'Este módulo ofrece la oportunidad de conocer el estado del arte en Inteligencia Artificial y la forma en que está redefiniendo las fronteras de la publicidad y el marketing. Analizaremos cómo la IA, una vez considerada incapaz de emular la creatividad humana, ahora potencia...',
        image: '/images/programa2.png',
        link: '/programa10'
    }
];


const Courses = () => {

    return (
        <Container id="programas" className={`fade-in`}>
            <h1>Programa</h1>
            <Row>
                {courses.map((course, index) => (
                    <Col md={4} sm={6} key={index} className="mb-4">
                        <Card>
                            <Link to={course.link} className="text-decoration-none">
                                <Card.Img variant="top" src={course.image} alt={course.title} />
                                <Card.Body>
                                    <Card.Title><h5>{course.title}</h5></Card.Title>
                                    <Card.Text>{course.description}</Card.Text>
                                    <Button as={Link} to={course.link} variant="primary">Más información</Button>
                                </Card.Body>
                            </Link>    
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default Courses;
