import React, { useState } from 'react';
import { Container, Image} from 'react-bootstrap';
import ProgramaMenu from '../ProgramaMenu';  
import useIntersectionObserver from '../../hooks/useIntersectionObserver';


const Programa8 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useIntersectionObserver(setIsVisible);
    return (
        <div>
            <div className='headermodule'>
                <Image src="/images/banner_vml.jpg" fluid />
            </div>
            <ProgramaMenu/>
            <Container ref={ref} className={`paddcontainer opacitycontainer ${isVisible ? 'fade-in' : ''}`}>
                <h1>Programa 7: Customer experience - Creando conexiones de valor</h1>
                <div className='fechaprograma'><h3>Fecha:</h3><p>Jueves 22 de agosto</p></div>
                <p>En el competitivo panorama actual, comprender, optimizar y mejorar la experiencia del cliente (CX) se ha convertido en un factor crítico para el éxito de cualquier marca.</p>
                <p>En este módulo abordaremos la evolución del concepto, su importancia estratégica y su impacto en los resultados de negocio; exploraremos diversas herramientas para analizar, diseñar e implementar estrategias de Customer Experience efectivas; y estudiaremos casos de éxito que crean conexiones de valor con los clientes como elemento crucial para el éxito sostenible de las marcas.</p>

                <h3>Modalidad:</h3>
                <p>Teórica</p>
                <h3>Imparten:</h3>
                <div className='persona'>
                    <p><Image src="/images/personas/valentina_more.png" fluid /><a href="https://www.linkedin.com/in/valentina-mor%C3%A9-14a55b26/" target="_blank" rel="noopener noreferrer">Valentina Moré, CEO VML</a></p>
                    <p><Image src="/images/personas/rodrigo_melian.png" fluid /><a href="https://www.linkedin.com/in/rodrigomelian/" target="_blank" rel="noopener noreferrer">Rodrigo Melián, VP Digital VML</a>.</p>
                    <p><Image src="/images/personas/mariana_cabrera.png" fluid /><a href="https://www.linkedin.com/in/mariana-cabrera-09883b12/" target="_blank" rel="noopener noreferrer">Mariana Cabrera, Planner VML</a></p>
                </div>
            </Container>
        </div>
    );
};

export default Programa8;
