import React, { useState } from 'react';
import { Container, Image} from 'react-bootstrap';
import ProgramaMenu from '../ProgramaMenu';  
import useIntersectionObserver from '../../hooks/useIntersectionObserver';


const Programa4 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useIntersectionObserver(setIsVisible);
    return (
        <div>
            <div className='headermodule'>
                <Image src="/images/banner_vml.jpg" fluid />
            </div>
            <ProgramaMenu/>
            <Container ref={ref} className={`paddcontainer opacitycontainer ${isVisible ? 'fade-in' : ''}`}>
                <h1>Programa 2: Planificación estratégica - Enfoque y eficacia</h1>
                <div className='fechaprograma'><h3>Fecha:</h3><p>Martes 6 de agosto</p></div>
                <p>En el dinámico panorama actual de la comunicación, la capacidad de elaborar e implementar estrategias efectivas es crucial para el éxito. Este módulo proporcionará las herramientas y el conocimiento necesarios para destacarse en este desafiante entorno.</p>
                <p>A lo largo de la misma, exploraremos los fundamentos de la comunicación estratégica, desde la investigación, el análisis de datos y tendencias, la generación de insights y la elaboración de mensajes relevantes hasta las herramientas y métricas clave para evaluar el impacto y la eficacia de las estrategias.</p>
                <h3>Modalidad:</h3>
                <p>Teórica y práctica</p>
                <h3>Imparten:</h3>
                <div className='persona'>
                    <p><Image src="/images/personas/mariana_cabrera.png" fluid /><a href="https://www.linkedin.com/in/mariana-cabrera-09883b12/" target="_blank" rel="noopener noreferrer">Mariana Cabrera, Planner VML</a>.</p>
                    <p><Image src="/images/personas/patricia_gomez.png" fluid /><a href="https://www.linkedin.com/in/patricia-g%C3%B3mez-pellegrini-63a30135/" target="_blank" rel="noopener noreferrer">Patricia Gómez, Directora de Cuentas VML</a>.</p>
                </div>    
            </Container>
        </div>
    );
};

export default Programa4;
